<template>
  <div aria-hidden="true">
    <ul class="grid gap-lg">
      
      <li class="col">
        <!-- Heading -->
        <ul class="grid gap-sm margin-bottom-md">
          <li class="col-8">
            <div class="ske ske--heading"></div>
          </li>
          
          <li>
            <div class="ske ske--heading"></div>
          </li>
          
          <li class="col-6">
            <div class="ske ske--heading"></div>
          </li>
          
          <li>
            <div class="ske ske--text"></div>
          </li>
        </ul><!-- End heading -->
        
        <!-- User -->
        <ul class="grid gap-sm items-center">
          <!-- Avatar -->
          <li class="col-2">
            <div class="ske ske--circle"></div>
          </li>
          
          <!-- Name -->
          <li class="col-6">
            <ul class="grid gap-xxs">
              <li class="col-8">
                <div class="ske ske--text"></div>
              </li>
      
              <li>
                <div class="ske ske--text text-sm"></div>
              </li>
            </ul>
          </li>
        </ul><!-- End user -->
      </li>
      
      <!-- Form -->
      <li class="col">
        <ul class="grid gap-sm">
          <li class="col-6">
            <div class="ske ske--heading"></div>
          </li>

          <li class="col-6">
            <div class="ske ske--heading"></div>
          </li>
          
          <li>
            <div class="ske ske--heading"></div>
          </li>
          
          <li>
            <div class="ske ske--heading"></div>
          </li>
          
          <li>
            <div class="ske ske--heading"></div>
          </li>
          
          <li>
            <div class="ske ske--heading"></div>
          </li>
        </ul>
      </li><!-- End form -->
    </ul>
  </div>
</template>
